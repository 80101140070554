//import svg from svgs/ folder
import React from 'react';
import {ReactComponent as Database} from '../svgs/database.svg';
import {ReactComponent as Analysis} from '../svgs/analysis.svg';
import {ReactComponent as Tools} from '../svgs/tools.svg';

import '../styles/home.scss';
import '../styles/Mobile/home.scss';

function Home() {
  //check if is mobile device
  const [isMobileDevice, setIsMobileDevice] = React.useState(window.innerWidth < 768);

  React.useEffect(() => {
    window.addEventListener('resize', () => {
      setIsMobileDevice(window.innerWidth < 768);
    });
  }, []);




  return (
    <div className={`homeSection ${isMobileDevice ? 'mobile' : 'desktop'}`}>
      <div className="homeContent">
        <div className='homeLogo'>
          <img src={`${process.env.PUBLIC_URL}/images/logo.png`} alt='logo' onClick={() => window.location.href = '/'} />
          {
            isMobileDevice &&
            <div className="logoText">
              <span className="textItem">Database</span>
              <div className="dot"></div>
              <span className="textItem">Analysis</span>
              <div className="dot"></div>
              <span className="textItem">Tools</span>
            </div>
          }
        </div>
        <div className="functionalityContent">
          <div className="functionality database-func">
            <div className="iconContainer">
              <Database />
            </div>
            <h2>Database</h2>
            <div className="desc">
              <p>Manage your database <strong>easily</strong> and <strong>efficiently</strong> with <strong>our tools</strong></p>
            </div>
            <div className="gifContainer">
              <img src={`${process.env.PUBLIC_URL}/images/database.gif`} alt="database gif" />
            </div>
          </div>
          <div className="functionality analysis-func">
            <div className="iconContainer">
              <Analysis />
            </div>
            <h2>Analysis</h2>
            <div className="desc">
            <p>To get the best results, you need to <strong>analyze</strong> your data</p>
            </div>
            <div className="gifContainer">
              <img src={`${process.env.PUBLIC_URL}/images/analysis.gif`} alt="database gif" />
            </div>
          </div>
          <div className="functionality tools-func">
            <div className="iconContainer">
              <Tools />
            </div>
            <h2>Tools</h2>
            
            <div className="desc">
            <p>Use our <strong>tools</strong> to <strong>optimize</strong> your database and <strong>workflow</strong></p>
            </div>
            <div className="gifContainer">
              <img src={`${process.env.PUBLIC_URL}/images/tools.gif`} alt="database gif" />
            </div>
          </div>
        </div>
      </div>
      <div className="footer">
        <p>Privacy Policy</p>
        <p>Terms of Service</p>
        <p>Cookie Policy</p>
        <p>© 2024 All rights reserved</p>
      </div>
    </div>
  );
}

export default Home;