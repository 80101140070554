import React, { useState, useEffect } from 'react';
import Dropdown from "../dropdown/Dropdown/Dropdown";
import DropdownItem from "../dropdown/DropdownItem/DropdownItem";
import MultiSelect from "../MultiSelect";


function GenereicAddItemForm({handleSubmitCall, fields}) {   
    const [dropDownsContent, setDropDownsContent] = useState({});
    const [files, setFiles] = useState([]);
    const [multiSelectItems, setMultiSelectItems] = useState({});

    console.log(dropDownsContent);


    useEffect(() => {
        // api.get("/api/incoming_shipments/")
        //     .then((res) => res.data)
        //     .then((data) => {
        //         console.log(data);
        //         setShipments(data);
        //     })
        //     .catch((err) => alert(err));

        let dropDownsContentToAdd = {};

        fields.forEach(field => {
            if(field.type === "select") {
                //incase of select field
                dropDownsContentToAdd[field.name] = {
                    "content": "Select a " + field.name,
                    "values": field.values
                }
            }
        });

        setDropDownsContent(dropDownsContentToAdd);
    }, [fields]);

    const handleFileChange = (e, name) => {
        setFiles({...files, [name]: e.target.files[0]});
    }

    const handleMultiSelectChange = (items,name) => {
        setMultiSelectItems({...multiSelectItems, [name]: items});
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        let formData = new FormData();

        console.log("fields", fields);

        fields.forEach(field => {
            console.log("field", field);
            if(field.type === "file") {
                formData.append(field.name, files[field.name]);
            } else if (field.type === "images") {
                for (let i = 0; i < files[field.name].length; i++) {
                    formData.append(field.name, files[field.name][i]);
                }
            } else if (field.type === "multiselect") {
                for (let i = 0; i < multiSelectItems[field.name].length; i++) {
                    formData.append(field.name, multiSelectItems[field.name][i]);
                }
            }
            else if (field.type === "select") {
                formData.append(field.name, dropDownsContent[field.name]["content"]);
            } else {
                formData.append(field.name, e.target[field.name].value);
                console.log(e.target[field.name].value);
            }

            console.log("formData", formData);

        }
        );

        console.log(formData);

        await handleSubmitCall(formData);
    }


    

    return (
        <div className="submit-item-folder">
            <form onSubmit={handleSubmit}>
                {
                    fields.map((field, id) => {
                        switch(field.type) {
                            case "select":
                                if (dropDownsContent[field.name] === undefined) {
                                    return <></>
                                }
                                return(
                                <>
                                    <label htmlFor={field.name}>{field.label}</label>
                                    <Dropdown
                                        buttonText= {dropDownsContent[field.name]["content"]}
                                        content={
                                            <>
                                            {dropDownsContent[field.name]["values"].map(value => (
                                                <DropdownItem key={value+"_item"} onClick={
                                                    (e) => {
                                                        setDropDownsContent({...dropDownsContent, [field.name]: {
                                                            content: value,
                                                            values: dropDownsContent[field.name]["values"]
                                                        }});
                                                    }
                                                }>{`${value}`}</DropdownItem>
                                            ))}
                                            </>
                                        }
                                    />
                                </>
                                )
                            case "file":
                                return(
                                <>
                                    <label htmlFor={field.name}>{field.label}</label>
                                    <input className="imageInput" type={field.type} onChange={(e)=>handleFileChange(e, field.name)} />
                                </>
                                )
                            case 'images':
                                return(
                                <>
                                    <label htmlFor={field.name}>{field.label}</label>
                                    <input className="imageInput" type="file" name={field.name} onChange={(e)=>handleFileChange(e, field.name)} multiple />
                                </>
                                )
                            case "multiselect":
                                return(
                                    <>
                                        <label htmlFor={field.name}>{field.label}</label>
                                        <MultiSelect items={field.values} onChange={(items) => handleMultiSelectChange(items, field.name)} />
                                    </>
                                )
                                
                            default:
                                return(
                                <>
                                    <label htmlFor={field.name}>{field.label}</label>
                                    <input type={field.type} id={field.name} name={field.name} />
                                </>
                                )
                        }
                    })
                }
                <button type="submit">Submit</button>
            </form>
        </div>
      );
}

export default GenereicAddItemForm;