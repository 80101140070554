import React, { useState } from 'react';
import axios from 'axios';

import api from '../api';

function CardMatcher() {
    const [image, setImage] = useState(null);
    const [results, setResults] = useState([]);

    const handleFileChange = (event) => {
        setImage(event.target.files[0]);
    };

    const handleSubmit = async () => {
        // const formData = new FormData();
        // formData.append('image', image);

        // try {
        //     const response = await axios.post('/pokemon_cards/match/', formData, {
        //         headers: { 'Content-Type': 'multipart/form-data' },
        //     });
        //     setResults(response.data.matches);
        // } catch (error) {
        //     console.error('Error matching card:', error);
        // }

        const formData = new FormData();
        formData.append('image', image);

        try {
            const response = await api.post('/pokemon_cards/match/', formData, {
                headers: { 'Content-Type': 'multipart/form-data' },
            });
            setResults(response.data.matches);
        } catch (error) {
            console.error('Error matching card:', error);
        }
        
    };

    return (
        <div>
            <h1>Card Matcher</h1>
            <input type="file" onChange={handleFileChange} />
            <button onClick={handleSubmit}>Find Match</button>
            <div>
                {results.length > 0 ? (
                    results.map((card) => (
                        <div key={card.id}>
                            <p>{card.name}</p>
                            <p>{card.rarity}</p>
                            <img src={card.image} alt={card.name} />
                        </div>
                    ))
                ) : (
                    <p>No matches found</p>
                )}
            </div>
        </div>
    );
}


function Test() {
    return (
        <div>
            <CardMatcher />
        </div>
    );
}

export default Test;
