import React, {useState} from 'react';
import {BrowserRouter, Routes, Route, Navigation, Navigate} from 'react-router-dom';
import Home from './pages/Home';
import Login from './pages/Auth/Login.js';
import Register from './pages/Auth/Register.js';
import NotFound from './pages/NotFound';
import ProtecedRoute from './components/ProtectedRoute';
import Header from './components/Header';
import "./styles/body.css";
import Stock from './pages/Sections/Products/Stock.js';
import AddProduct from './pages/Sections/Products/AddProduct.js';
import Purchases from './pages/Sections/Purchases/Purchases.js';
import AddPurchase from './pages/Sections/Purchases/AddPurchase.js';
import IncomingShipments from './pages/Sections/IncomingShipments/IncomingShipments.js';
import AddIncomingShipments from './pages/Sections/IncomingShipments/AddIncomingShipments.js';
import ImportData from './pages/Tools/ImportData.js';
import InsertionBatches from './pages/Sections/InsertionBatches/InsertionBatches.js';
import AddInsertionBatch from './pages/Sections/InsertionBatches/AddInsertionBatch.js';
import Test from './pages/Test.js';


function Logout() {
  localStorage.clear();
  return <Navigate to="/login" />;
}

function RegisterAndLogout() {
  localStorage.clear();
  return <Register />;
}

function App() {


  const [isAuth, setIsAuth] = useState(false);

  return (
  <>
  <Header isAuth={isAuth} setIsAuth={setIsAuth} />
    <BrowserRouter>
      <Routes>
        <Route 
          path = "/"
          element={
            <ProtecedRoute>
              <Home />
            </ProtecedRoute>
          }
        />
        <Route 
          path="database/stock" 
          element={
            <ProtecedRoute>
              <Stock />
            </ProtecedRoute>
            } 
        />
        <Route 
          path="database/stock/add_product" 
          element={
            <ProtecedRoute>
              <AddProduct />
            </ProtecedRoute>
            } 
        />


        <Route 
          path="database/purchases" 
          element={
            <ProtecedRoute>
              <Purchases />
            </ProtecedRoute>
            } 
        />
        <Route 
          path="database/purchases/add" 
          element={
            <ProtecedRoute>
              <AddPurchase />
            </ProtecedRoute>
            } 
        />


        <Route 
          path="database/incomingstock" 
          element={
            <ProtecedRoute>
              <IncomingShipments />
            </ProtecedRoute>
            } 
        />
        <Route 
          path="database/incomingstock/add" 
          element={
            <ProtecedRoute>
              <AddIncomingShipments />
            </ProtecedRoute>
            } 
        />

        <Route 
          path="database/insertion_batches" 
          element={
            <ProtecedRoute>
              <InsertionBatches />
            </ProtecedRoute>
            } 
        />

        <Route 
          path="database/insertion_batch/add" 
          element={
            <ProtecedRoute>
              <AddInsertionBatch />
            </ProtecedRoute>
            } 
        />

        <Route 
          path="tools/import_data" 
          element={
            <ProtecedRoute>
              <ImportData />
            </ProtecedRoute>
            } 
        />

        <Route path="/login" element={<Login setIsAuth={setIsAuth} />} />
        <Route path="/register" element={<RegisterAndLogout />} />
        <Route path="/logout" element={<Logout />} />
        <Route path="*" element={<NotFound/>} />

        <Route path="/test" element={<Test/>} />
      </Routes>
    </BrowserRouter> 
  </>
  );
}

export default App;