
import '../../styles/genericTable.scss';
import { useState } from 'react';


const formatters = {
  date: (date) => new Date(date).toLocaleDateString('en-GB', {
    day: 'numeric',
    month: 'short',
    year: 'numeric'
  }),
  price: (price) => new Intl.NumberFormat('en-GB', {
    style: 'currency',
    currency: 'EUR'
  }).format(price),
  weight: (weight) => new Intl.NumberFormat('en-GB', {
    style: 'unit',
    unit: 'gram',
    unitDisplay: 'short'
  }).format(weight),
  image: (image) => <img className="image" src={image} alt='image'/>,
  images: (images) => {
    // <div className="images">
    //   {images.map((image) => <img src={image['image']} alt='image'/>)}
    // </div>
    let image = images.length > 0 ? images[0]['image'] : "";

    return <img className="image" src={image} alt='image'/>
  },
  link: (link) => <a href={link}>Link</a>
}

const keyWordsFormatters = {
  date: formatters.date,
  price: formatters.price,
  totalCost: formatters.price,
  shipmentPrice: formatters.price,
  ivaPrice: formatters.price,
  weight: formatters.weight,
  totalWeight: formatters.weight,
  image: formatters.image,
  images: formatters.images,
  link: formatters.link
}

function GenericTable({ columns, keys, data, onRowClick }) {
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);



  return (
  <div className="table-container">
    <table>
      <thead>
        <tr>
          {columns.map((column) => (
            <th key={column}>{column}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data.map((row) => {
          if (data.indexOf(row) < ((currentPage) * rowsPerPage) || data.indexOf(row) >= ((currentPage+1) * rowsPerPage)) {
            return null;
          }
          else {
            return (
              <tr key={row.id} onClick={() => onRowClick(row)}>
                {keys.map((column) => {
                  return (
                    <td key={column} className={column}>
                      {keyWordsFormatters[column] ? keyWordsFormatters[column](row[column]) : row[column]}
                    </td>
                  )
                }
                )}
              </tr>
            );
          }
        })}
        <div className="pagination">
          <button onClick={() => setCurrentPage(currentPage-1)} disabled={currentPage === 0}>Previous</button>
          <button onClick={() => setCurrentPage(currentPage+1)} disabled={currentPage === Math.floor(data.length/rowsPerPage)}>Next</button>
          <select onChange={(e) => {
            setRowsPerPage(parseInt(e.target.value))
            if (currentPage > Math.floor(data.length/parseInt(e.target.value))) {
              setCurrentPage(Math.floor(data.length/parseInt(e.target.value)));
            }
          }} value={rowsPerPage}>
            <option value="5">5</option>
            <option value="10">10</option>
            <option value="20">20</option>
          </select>
        </div>
      </tbody>
    </table>
  </div>
  );
}

export default GenericTable;