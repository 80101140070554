import React, { useState,useEffect } from 'react';
import api from '../../../api';
import PopupItem from '../../../components/popUp/PopupItem';
import InsertionBatchDetail from './InsertionBatchDetail.js';


import GenericTable from '../../../components/Table/GenericTable';

function InsertionBatches() {
    const [insertionBatches, setInsertionBatches] = useState([]);
    const [popUpContent, setPopUpContent] = useState(null);

    useEffect(() => {
        api.get("/api/insertion_batches/").then((res) => res.data).then((data) => {
            console.log("insertionBatches",data);

            setInsertionBatches(data);
        }).catch((err) => alert(err));
    }
    , []);


    return (
        <div className="InsertionBatchSection tableSection">
            <div className="sectionTitle">
                <span>Purchases</span>
            </div>
            <div className="addButton" onClick={() => {window.location.href = "/database/Insertion_batch/add"}}>
                Add Insertion
            </div>
            <div className="InsertionBatchContainer">
                <GenericTable
                    columns={["Name", "Images", "Status"]}
                    keys={['name', 'images', 'status']}
                    data={insertionBatches}
                    onRowClick={(row) => {
                        console.log("test",row);
                        setPopUpContent(row);
                    }}
                />
                <PopupItem  onClose={() => setPopUpContent(null)}  visible={popUpContent}>
                    {
                        popUpContent && <InsertionBatchDetail insertionBatch={popUpContent}/>
                    }
                </PopupItem>
                
            </div>
        </div>
    );
}

export default InsertionBatches;