import React from 'react';
import MobileTable from '../../../components/Table/MobileTable';


function MobileIncomingStock({shipments,parameters}) {
    return(
        <MobileTable title="Shipments" items={shipments} parameters={parameters} />
    )
}

export default MobileIncomingStock;