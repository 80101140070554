import "../../styles/ImportData.scss";
import { useState } from "react";
import PopupItem from "../../components/popUp/PopupItem";
import api from "../../api";

function ImportData() {
  const [popUpContent, setPopUpContent] = useState(null);
  
  const handleZenmarketImport = () => {
    setPopUpContent(true);
  }

  const importZenmarket = () => {
    api.post("api/tools/import_zenmarket/", {auth_token: document.querySelector(".popupInput").value})
  }
  
  return (
    <div className="import-data-section">
        <div className="title">
            Import Data
        </div>
        <div className="import-data-button import-zenmarket" onClick={handleZenmarketImport}>
            Import Data From ZenMarket
        </div>
        <PopupItem  onClose={() => setPopUpContent(null)}  visible={popUpContent}>
            <div className="popupContent">
                <div className="popupTitle">
                    Import Data From ZenMarket
                </div>
                <div className="popupDescription">
                    <span>Enter the Cookie of the ZenMarket page you want to import</span>
                </div>
                <input type="text" placeholder="Cookie" className="popupInput" />
                <div className="popupButton" onClick={importZenmarket}>
                    Import
                </div>
            </div>
        </PopupItem>
    </div>
  );
}

export default ImportData;