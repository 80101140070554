import axios from 'axios';
import {ACCESS_TOKEN,REFRESH_TOKEN} from './constants';


const api = axios.create({
    baseURL: "https://poketrader.online",
    //baseURL: "http://localhost:8000",
});

// Function to refresh the access token
const refreshToken = async () => {
    const refresh = localStorage.getItem(REFRESH_TOKEN);
    if (!refresh) throw new Error("No refresh token available");

    const response = await axios.post("http://localhost:8000/api/token/refresh/", { refresh });
    const { access } = response.data;

    localStorage.setItem(ACCESS_TOKEN, access);
    return access;
};

api.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem(ACCESS_TOKEN);
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

// Response interceptor to handle 401 errors
api.interceptors.response.use(
    (response) => response,
    async (error) => {
        if (error.response?.status === 401) {
            try {
                const newAccessToken = await refreshToken();
                error.config.headers.Authorization = `Bearer ${newAccessToken}`;
                return api.request(error.config); // Retry the original request
            } catch (refreshError) {
                console.error("Token refresh failed", refreshError);
                // Optionally redirect to login page
                window.location.href = "/login";
            }
        }
        return Promise.reject(error);
    }
);

export default api;