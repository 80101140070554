import {Navigate} from 'react-router-dom';
import {jwtDecode} from 'jwt-decode';
import api from '../api';
import {ACCESS_TOKEN, REFRESH_TOKEN} from '../constants';
import {useState,useEffect} from 'react';

// children is a prop that represents the content between the opening and closing tags of a component
function ProtectedRoute({children}){
    const [isAuthorized, setIsAuthorized] = useState(null);

    useEffect(() => {
        auth().catch(()=>setIsAuthorized(false));
    }, []);

    const refreshToken = async () => {
        try {
            const token = localStorage.getItem(REFRESH_TOKEN);
            const response = await api.post('/api/token/refresh/', {refresh: token});
            if (response.status !== 200) {
                setIsAuthorized(false);
            }
            else {
                localStorage.setItem(ACCESS_TOKEN, response.data.access);
                setIsAuthorized(true);
            }
        } catch (error) {
            console.error(error);
            setIsAuthorized(false);
        }
    };

    const auth = async () => {
        const token = localStorage.getItem(ACCESS_TOKEN);
        if (!token) {
            setIsAuthorized(false);
            return;
        }
        const decoded = jwtDecode(token);
        if (decoded.exp * 1000 < Date.now()) {
            await refreshToken();
        } else {
            setIsAuthorized(true);
        }
    }
    

    if (isAuthorized === null) {
        return <div>Loading...</div>;
    }

    return isAuthorized ? children : <Navigate to="/login"/>;
}

export default ProtectedRoute;