import React, { useState,useEffect } from 'react';
import api from '../../../api';
import PopupItem from '../../../components/popUp/PopupItem';
import PurchaseDetails from './PurchaseDetails';
import MobilePurchases from './MobilePurchases';




import GenericTable from '../../../components/Table/GenericTable';

function Purchases() {
    const [isMobileDevice, setIsMobileDevice] = useState(window.innerWidth < 768);
    const [purchases, setPurchases] = useState([]);
    const [popUpContent, setPopUpContent] = useState(null);

    useEffect(() => {
        window.addEventListener('resize', () => {
            setIsMobileDevice(window.innerWidth < 768);
        }
        );
    }, []);

    

    const analyzePurchases = (purchases) => {
        purchases.forEach((purchase) => {
            purchase['productsQuantity'] = purchase.products.length;
            //calculate if estimated cost is enough or not (if not it means that some products are missing and need to be added)
            let estimatedCost = 0;
            purchase.products.forEach((product) => {
                estimatedCost += product.estimated_cost;
            });
            purchase['estimatedCost'] = estimatedCost;
            //check if estimated cost is enough (5% tolerance)
            purchase['isEstimatedCostEnough'] = purchase.price <= estimatedCost * 1.05 && purchase.price >= estimatedCost * 0.95;

            purchase['action'] = purchase['isEstimatedCostEnough'] ? <span className="green">OK</span> : <span className="red">Missing products</span>;
            
            console.log(purchase['estimatedCost']);  
        });
    }

    
    analyzePurchases(purchases);


    useEffect(() => {
        api.get("/api/bought_stocks/").then((res) => res.data).then((data) => {
            console.log("purchases",data);

            analyzePurchases(data);
            setPurchases(data);

            //get all link ?data=value&data2=value2 from current page
            const urlParams = new URLSearchParams(window.location.search);
            console.log(urlParams);
            if (urlParams.get('inspect_purchase')) {
                //directly open the popup
                let purchase = data.find((purchase) => purchase.id == urlParams.get('inspect_purchase'));
                console.log("purchase",purchase);
                setPopUpContent(purchase);
            }


        }).catch((err) => alert(err));

        
        
    }
    , []);

    let parameters = ["name"];

    return (
        <div className="purchaseSection tableSection">
        {
            isMobileDevice ? <MobilePurchases purchases={purchases} parameters={parameters} /> :<>
            <div className="sectionTitle">
                <span>Purchases</span>
            </div>
            <div className="addButton" onClick={() => {window.location.href = "/database/purchases/add"}}>
                Add Purchase
            </div>
            <div className="purchaseContainer">
                <GenericTable
                    columns={["Name", "Date", "Price", "Weight", "Images", "Status", "Shipment", "Action"]}
                    keys={["name", "date", "price", "weight", "images", "state", "incomingShipment", "action"]}
                    data={purchases}
                    onRowClick={(row) => {
                        console.log("test",row);
                        setPopUpContent(row);
                    }}
                />
                <PopupItem  onClose={() => setPopUpContent(null)}  visible={popUpContent}>
                    {
                        popUpContent && <PurchaseDetails purchase={popUpContent}/>
                    }
                </PopupItem>
                
            </div>
            </>
        }
        </div>
    );
}

export default Purchases;