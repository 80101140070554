import React from 'react';
import '../../../styles/IncomingShipments.scss';
import api from '../../../api';
import {ReactComponent as Remove} from '../../../svgs/remove.svg';

const MiniPurchase = ({purchase,children,onRemove,shipment_id}) => {
  return (
    <div className="purchase" key={purchase.id} onClick={() => window.location.href = `/database/purchases?inspect_purchase=${purchase.id}&shipment_link=${shipment_id}`}>
      {
        onRemove && <Remove className='remove-button' onClick={onRemove}/>
      }
      <div className="purchase-info">
        <span className='purchase-title' title={purchase.name}>{purchase.name}</span>
        <span><strong>Price: </strong> {purchase.price.toFixed(2) + " €"}</span>
      </div>
      <div className='slim-divider'/>
      {
        purchase.items && purchase.items.map((item, index) => {
          return (
            <p><span>{item.name}</span> {item.quantity}</p>
          );
        })
      }
      <div className='add-item-button-container'>
        {children}
      </div>
    </div>
  );
};




const IncomingShippingDetails = ({shipment}) => {
  const [purchasesList, setPurchasesList] = React.useState([]);

  const handleAddItemClick = () => {
    if (purchasesList.length === 0) {
      fetchPurchasesList();
    } else {
      setPurchasesList([]);
    }
  }

  const fetchPurchasesList = () => {
    api.get("/api/bought_stocks/").then((res) => res.data).then((data) => {
      data = data.filter((purchase) => purchase.incomingShipment === null);
      setPurchasesList(data);
    }).catch((err) => alert(err));

  }

  const handleAddingPurchase = (purchase) => {
    api.patch(`/api/bought_stocks/update/${purchase.id}/`, {
      incomingShipment: shipment.id
    }).then((res) => {
      setPurchasesList(purchasesList.filter((p) => p.id !== purchase.id));
      shipment.bought_stocks.push(purchase);
    }).catch((err) => alert(err));
  }

  const removePurchaseHandler = (purchase) => {
    api.patch(`/api/bought_stocks/update/${purchase.id}/`, {
      incomingShipment: null
    }).then((res) => {
      purchase.incomingShipment = null;
      shipment.bought_stocks = shipment.bought_stocks.filter((p) => p.id !== purchase.id);
      setPurchasesList([...purchasesList,purchase]);

    }).catch((err) => alert(err));
  }

  return (
    <div className="shipping-details">
      <h2>Shipping Details</h2>
      <div className="content">
        <div className="info-section">
          <h3>INFO</h3>
          <p><span>Courier</span> {shipment.courier}</p>
          <p><span>Status</span> <span>{shipment.state} <span className="status-indicator"></span></span></p>
          <p><span>Trck Number</span> {shipment.trackingNumber}</p>
          <p><span>Total Weight</span> {shipment.totalWeight} g</p>
          <br/>
          <h3>COST</h3>
          <p><span>Items Cost</span> {shipment.totalCost.toFixed(2) + " €"} </p>
          <p><span>Shipping Cost</span> {shipment.shipmentPrice.toFixed(2) + " €"} </p>
          <p><span>IVA+Tax Price</span> {shipment.ivaPrice.toFixed(2) + " €"} </p>
          <div className='slim-divider'/>
          <p><span>Total</span> {(shipment.totalCost + shipment.shipmentPrice + shipment.ivaPrice).toFixed(2) + " €"} </p>
        </div>
        <div className="purchases-section">
          <h3>PURCHASES</h3>
          <div className="purchases">
            {
              shipment.bought_stocks && shipment.bought_stocks.map((purchase, index) => <MiniPurchase purchase={purchase} key={index} onRemove={() => removePurchaseHandler(purchase)} shipment_id={shipment.id}/>)
            }
            {/* <div className="purchase">
              <div className="purchase-info">
                <span className='purchase-title'>CharizardEx near mint + 151 Pack for free</span>
                <br/>
                <span><strong>Price: </strong> 85.00$</span>
              </div>
              <div className='slim-divider'/>
              <p><span>151 Pack</span> 1</p>
              <p><span>Charizard Ex</span> 1</p>
              <p><span>Card Bulk</span> 100</p>
            </div> */}
            <button className="add-item-button" onClick={handleAddItemClick}>ADD ITEM</button>
            {
              purchasesList && purchasesList.map((purchase, index) => {

                return(
                  <MiniPurchase purchase={purchase} key={index} shipment_id={shipment.id}>
                    <button className="add-item-button" onClick={()=>handleAddingPurchase(purchase)}>ADD</button>
                  </MiniPurchase>
                )
              }
            )
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default IncomingShippingDetails;