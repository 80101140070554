import { useState, useEffect } from "react";
import "../../../styles/stock.scss";
import api from '../../../api';
import GenericTable from '../../../components/Table/GenericTable';
import MobileStock from './MobileStock';


function Stock() {
    const [products, setProducts] = useState([]);
    const [isMobileDevice, setIsMobileDevice] = useState(window.innerWidth < 768);
  
    useEffect(() => {
      window.addEventListener('resize', () => {
        setIsMobileDevice(window.innerWidth < 768);
      });
    }, []);
  

    console.log(api.defaults.baseURL);

    useEffect(() => {
        getProducts();
    }, []);

    const getProducts = () => {
        api
            .get("/api/products/")
            .then((res) => res.data)
            .then((data) => {
                console.log(data);
                //format some data

                data.forEach((product) => {
                    product.type = product.type_details ? product.type_details.name : "N/A";
                    //get subTypes
                    let subTypes = product.type_details ? product.type_details.subtypes : [];
                    //find subType with id product.sub_type
                    product.subType = subTypes.find((subType) => subType.id === product.sub_type)?.name;
                });

                setProducts(data);
            })
            .catch((err) => alert(err));
    };
    
    const addStock = () => {
        window.location.href = "/database/stock/add_product";
    }


    const parameters = ["type", "subType", "estimated_cost", "origin"];

    return (
        <div className="stockSection">
            {
                isMobileDevice ? <MobileStock products={products} parameters={parameters}/> :
                <div className="stockContent">
                    <div className="addStock" onClick={()=>addStock()}>
                        <div>Add stock</div>
                    </div>
                    <div className="stockContainer">
                        <GenericTable
                            columns={["Product", "Type","SubType", "Images", "Origin", "Bought Order", "Insertion Group", "Status"]}
                            keys={["name", "type","subType", "images", "origin", "boughtStock", "insertion_batch", "state"]}
                            data={products}
                            onRowClick={(row) => {
                                console.log("test",row);
                                //setPopUpContent(row);
                            }}
                        />
                    </div>
                </div>
            }
        </div>
    )
    ;
}

export default Stock;