import GenereicAddItemForm from "../../../components/Table/GenericAddItemForm";
import api from '../../../api';


function AddIncomingShipments() {


    //create handleSubmit, fields to be used in the form
    const handleSubmit = async (form) => {

        await api.post('/api/incoming_shipments/', form).then((res) => {
            console.log(res);
        }).catch((err) => {
            console.log(err);
        });

        console.log(form);
    }

    const fields = [
        {
            name: "courier",
            label: "Courier",
            type: "text"
        },
        {
            name: "state",
            label: "State",
            type: "select",
            values: ["Received", "In transit", "Shipped"]
        },
        {
            name: "trackingNumber",
            label: "Tracking Number",
            type: "text"
        },
        {
            name: "totalWeight",
            label: "Total Weight",
            type: "text"
        },
        {
            name: "totalCost",
            label: "Total Cost",
            type: "text"
        },
        {
            name: "shipmentPrice",
            label: "Shipment Price",
            type: "text"
        },
        {
            name: "ivaPrice",
            label: "IVA Price",
            type: "text"
        }
    ];

    return (
        <GenereicAddItemForm handleSubmitCall={handleSubmit} fields={fields} />
    );
}


export default AddIncomingShipments;