import React, { useEffect, useState } from 'react';
import api from '../../../api';
import Dropdown from "../../../components/dropdown/Dropdown/Dropdown";
import DropdownItem from "../../../components/dropdown/DropdownItem/DropdownItem";

function AddPurchase(){
    const [image, setImage] = useState(null);
    const [shipmentInputContent, setShipmentInputContent] = useState('Select a shipment');
    const [shipments, setShipments] = useState([]);

    useEffect(() => {
        api.get("/api/incoming_shipments/")
            .then((res) => res.data)
            .then((data) => {
                console.log(data);
                setShipments(data);
            })
            .catch((err) => alert(err));
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        const formData = new FormData();
        
        formData.append('name', e.target.name.value);
        formData.append('state', e.target.status.value);
        formData.append('link', e.target.link.value);
        formData.append('price', e.target.price.value.replace(',', '.'));
        formData.append('weight', e.target.weight.value);
        formData.append('shipment', shipmentInputContent);
        formData.append('images', image);


        try {
            const res = await api.post('/api/bought_stocks/', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            console.log(res);
        } catch (error) {
            alert(error);
        } finally {
            //change the route
            window.location.href = '/database/purchases';
        }

    }

    const handleChangeImage = (e) => {
        setImage(e.target.files[0]);
    }

    const handleClick = (e,shipment) => {
        setShipmentInputContent(shipment);
    }

    return (
        <div className="addPurchaseSection">
                <div className="addPurchaseContainer">
                    <div className="addPurchaseHeader">
                        <span>Add a new Purchase</span>
                    </div>
                    <div className="addProductForm submit-item-folder">
                        <form onSubmit={handleSubmit}>
                            <label htmlFor="name">Name</label>
                            <input type="text" id="name" name="name" />

                            <label htmlFor="status">Status</label>
                            <input type="text" id="status" name="status" />

                            <label htmlFor="link">Link</label>
                            <input type="text" id="link" name="link" />

                            <label htmlFor="price">Price</label>
                            <input type="text" id="price" name="price" />

                            <label htmlFor="weight">Weight</label>
                            <input type="text" id="weight" name="weight" />

                            <label htmlFor="shipment">Shipment</label>
                            <Dropdown
                                buttonText= {shipmentInputContent}
                                content={
                                    <>
                                    {shipments.map((shipment, id) => (
                                        <DropdownItem key={id} onClick={(e)=>handleClick(e,shipment.name)}>{`${shipment.name}`}</DropdownItem>
                                    ))}
                                    </>
                                }
                            />

                            <label htmlFor="image">Image</label>
                            <input className="imageInput" type="file" onChange={handleChangeImage} />
                            
                            <button type="submit">Submit</button>
                        </form>
                    </div>
                </div>
        </div>
      );
    }

    export default AddPurchase;