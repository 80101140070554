import { useEffect, useState } from 'react';
import '../../../styles/addProduct.scss';
import Dropdown from "../../../components/dropdown/Dropdown/Dropdown";
import DropdownItem from "../../../components/dropdown/DropdownItem/DropdownItem";
import api from '../../../api';

function AddProduct() {
    const [typeInputContent, settypeInputContent] = useState('Select a type');
    const [types, setTypes] = useState([]);
    const [subTypeInputContent, setSubTypeInputContent] = useState('Select a subtype');
    const boughtOrders = [ '#0001', '#0002', '#0003' ];
    const [boughtOrderInputContent, setboughtOrderInputContent] = useState('Select an order');
    const [isLoading, setLoading] = useState(false);
    const [imageToUpload, setImageToUpload] = useState(null);

    useEffect(() => {
        api.get("/api/types/")
            .then((res) => res.data)
            .then((data) => {
                setTypes(data);
            })
            .catch((err) => alert(err));
    }, []);

    const handleClick = (e,type) => {
        settypeInputContent(type);
        setSubTypeInputContent('Select a subtype');
    }

    const handleSubTypeClick = (e,subtype) => {
        setSubTypeInputContent(subtype);
    }

    const handleChangeImage = (e) => {
        setImageToUpload(e.target.files[0]);
    }

    const handleSubmit = async (e) => {
        setLoading(true);
        e.preventDefault();
    
        const formData = new FormData();
        formData.append('name', e.target.name.value);
        formData.append('quantity', e.target.quantity.value);
        // Ensure you're appending the type ID
        const selectedType = types.find(type => type.name === typeInputContent);
        if (selectedType) {
            formData.append('type', selectedType.id); // Make sure `selectedType.id` is a valid number
        } else {
            alert("Please select a valid type.");
            setLoading(false);
            return;
        }
        

        formData.append('image', imageToUpload);
        formData.append('origin', e.target.origin.value);
        formData.append('bought_order_link', boughtOrderInputContent !== 'Select a bought order' ? boughtOrderInputContent : '');
    
        try {
            const res = await api.post('/api/products/', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            console.log(res);
        } catch (error) {
            alert(error);
        } finally {
            setLoading(false);
            //change the route
            window.location.href = '/database/stock';
        }
    };


  return (
    <div className="addProductSection">
            <div className="addProductContainer">
                <div className="addProductHeader">
                    <span>Add a new Product</span>
                </div>
                <div className="addProductForm submit-item-folder">
                    <form onSubmit={handleSubmit}>
                        <label htmlFor="name">Name</label>
                        <input type="text" id="name" name="name" />
                        <label htmlFor="quantity">Quantity</label>
                        <input type="text" id="quantity" name="quantity" />
                        <label htmlFor="type">Type</label>
                        <Dropdown
                            buttonText= {typeInputContent}
                            content={
                                <>
                                {types.map((type, id) => (
                                    <DropdownItem key={id} onClick={(e)=>handleClick(e,type.name)}>{`${type.name}`}</DropdownItem>
                                ))}
                                </>
                            }
                        />
                        {
                            //Subtypes dropdown appear after selecting a type
                            typeInputContent !== 'Select a type' && types.find(type => type.name === typeInputContent).subtypes.length > 0 && 
                            <>
                            <label htmlFor="subtype">Subtype</label>
                            <Dropdown
                                buttonText= {subTypeInputContent}
                                content={
                                    <>
                                    {types.find(type => type.name === typeInputContent).subtypes.map((subtype, id) => (
                                        <DropdownItem key={id} onClick={(e)=>handleSubTypeClick(e,subtype.name)}>{`${subtype.name}`}</DropdownItem>
                                    ))}
                                    </>
                                }
                            />
                            </>
                        }
                        <label htmlFor="image">Image</label>
                        <input className="imageInput" type="file" onChange={handleChangeImage} />
                        <label htmlFor="origin">Origin</label>
                        <input type="text" id="origin" name="origin" />
                        <label htmlFor="bought_order_link">Bought order link</label>
                        <Dropdown
                        buttonText= {boughtOrderInputContent}
                        content={
                            <>
                            {boughtOrders.map((item, id) => (
                                <DropdownItem key={id} onClick={(e)=> setboughtOrderInputContent(item)}>{`${item}`}</DropdownItem>
                            ))}
                            </>
                        }
                        />
                        <button type="submit">Submit</button>
                    </form>
                </div>
            </div>
    </div>
  );
}

export default AddProduct;