import React, { useState,useEffect } from 'react';
import {ReactComponent as Remove} from '../../../svgs/remove.svg';
import "../../../styles/InsertionBatches.scss";
import InnerPopupItem from '../../../components/popUp/InnerPopupItem';
import GenereicAddItemForm from '../../../components/Table/GenericAddItemForm';
import api from '../../../api';



function MiniProduct({product, children, onRemove}) {
    return (
        <div className="product">
            {
            onRemove && <Remove className='remove-button' onClick={onRemove}/>
            }
            <div className="product-info">
            <span className='product-title' title={product.name}>{product.name}</span>
            </div>
            <div className='add-item-button-container'>
            {children}
            </div>
        </div>
        );
    }

function ImageScroll({images,imagesIndex, setImagesIndex}) {
    return (
        <>
            <h3>IMAGES</h3>
            <div className="images">
                {
                    images && images.length > 0 &&
                    <img src={images[imagesIndex]!== undefined ? images[imagesIndex]['image'] : ""} alt={"image"} className="main-image" />
                }
                <div className="scroll-left" onClick={() => setImagesIndex(imagesIndex === 0 ? images.length - 1 : imagesIndex - 1)}>&lt;</div>
                <div className="scroll-right" onClick={() => setImagesIndex(imagesIndex === images.length - 1 ? 0 : imagesIndex + 1)}>&gt;</div>
                <div className="images-indicators">
                    {/*Inserisci i pallini pieni e vuoti per indicare lo scorrimento delle imaggini e la current*/}
                    {
                        images.map((image, index) => <div key={index} className={imagesIndex === index ? "indicator active" : "indicator"}></div>)
                    }
                </div>
            </div>
        </>
    );
}

function InfoContent({insertionBatch, handleShipmentClick, handleCreateProductClick}) {
    return (
        <>
            <h3>INFO</h3>
            <p><span className='title'>Name</span> <span title={insertionBatch.name} className="value">{insertionBatch.name}</span></p>
        </>
    );
}


function ProductsSection({insertionBatch, productList, removeProductHandler, handleCreateProductClick, handleAddItemClick, handleAddingProduct}) {
    return (
        <div className="products-section">
            <h3>PRODUCTS</h3>
            <div className="products">
                {
                insertionBatch.products && insertionBatch.products.map((product, index) => <MiniProduct product={product} key={index} onRemove={() => removeProductHandler(product)}/>)
                }
                <button className="add-item-button" onClick={handleCreateProductClick}>CREATE PRODUCT</button>
                <button className="add-item-button" onClick={handleAddItemClick}>ADD PRODUCT</button>
                {
                productList && productList.map((product, index) => {
                    return(
                    <MiniProduct product={product} key={index}>
                        <button className="add-item-button" onClick={()=>handleAddingProduct(product)}>ADD</button>
                    </MiniProduct>
                    )
                }
                )
                }
            </div>
        </div>
    );
}


function ActionSection({insertionBatch, activateCreationPopup}) {
    return (
        <div className="action-section">
            <h3>ACTIONS</h3>
            <button className="action-button" onClick={activateCreationPopup}>CREATE INSERTION</button>
        </div>
    );
}

function InsertionCreation({insertionBatch,close}) {

    //create handleSubmit, fields to be used in the form
    const handleSubmit = async (form) => {

        form.append('insertion_batch', insertionBatch.id);
        await api.post('/api/insertions/', form).then((res) => {
            console.log(res);
            //redirect
            close();
        }).catch((err) => {
            console.log(err);
        });

        console.log(form);
    }

    const fields = [
        {
            name: "site",
            label: "Site",
            type: "select",
            values: ['Amazon', 'Ebay', 'Shopify']
        },
        {
            name: "price",
            label: "Price",
            type: "text",
        }
    ];
    return (
        <div className="InsertionCreation">
            <h2>Create Insertion</h2>
            <div className="content">
                <GenereicAddItemForm handleSubmitCall={handleSubmit} fields={fields} />
            </div>
        </div>
    );
}


function InsertionBatchDetail({ insertionBatch }) {
    const [imagesIndex, setImagesIndex] = useState(0);
    const [innerPopupContent, setInnerPopupContent] = useState(null);

    let images = insertionBatch.images;
    return (
        <div className="InsertionBatchDetails">
            <h2>Insertion Batch Details</h2>
            <div className="InsertionBatchDetailscontent">
                <div className="info-section">
                    <ImageScroll images={images} setImagesIndex={setImagesIndex} imagesIndex={imagesIndex} />
                    <InfoContent insertionBatch={insertionBatch} />
                    <ActionSection insertionBatch={insertionBatch} activateCreationPopup={() => setInnerPopupContent(true)}/>
                </div>
                <ProductsSection insertionBatch={insertionBatch} />
            </div>
            <InnerPopupItem  onClose={() => setInnerPopupContent(null)} visible={innerPopupContent}>
                {
                    innerPopupContent && <InsertionCreation insertionBatch={insertionBatch} close={() => setInnerPopupContent(null)}/>
                }
            </InnerPopupItem>
        </div>
    );
}

export default InsertionBatchDetail;