import GenericTable from "../../../components/Table/GenericTable";
import React, { useState, useEffect } from 'react';
import api from '../../../api';
import '../../../styles/IncomingShipments.scss'; 
import '../../../styles/IncomingShippingDetails.scss';

import PopupItem from '../../../components/popUp/PopupItem';
import IncomingShippingDetails from './IncomingShippingDetails';
import MobileIncomingStock from './MobileIncomingStock';



function IncomingShipments() {
  const [shipments, setShipments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [popUpContent, setPopUpContent] = useState(null);
  const [isMobileDevice, setIsMobileDevice] = useState(window.innerWidth < 768);

  useEffect(() => {
    window.addEventListener('resize', () => {
      setIsMobileDevice(window.innerWidth < 768);
    })});

    console.log("popupcontent",popUpContent);

  useEffect(() => {
    fetchShipments();
  }, []);


  async function fetchShipments() {

    await api.get('/api/incoming_shipments/').then(response => {

        //humanize financial data for better readability ****MOVED TO TABLE****
        // response.data.forEach((shipment) => {
        //     shipment.finalPrice = (shipment.totalCost + shipment.shipmentPrice + shipment.ivaPrice).toFixed(2) + " €";
        //     shipment.totalCost = shipment.totalCost.toFixed(2) + " €";
        //     shipment.shipmentPrice = shipment.shipmentPrice.toFixed(2) + " €";
        //     shipment.ivaPrice = shipment.ivaPrice.toFixed(2) + " €";
        //     shipment.totalWeight = shipment.totalWeight + " g";
        // });

        const shipment_id = new URLSearchParams(window.location.search).get('shipment_id');
        if (shipment_id) {
            const shipment = response.data.find((shipment) => shipment.id == shipment_id);
            setPopUpContent(shipment);
        }

        console.log("response",response.data);
        setShipments(response.data);
        setLoading(false);
        }).catch(error => {
            console.log(error);
        });
  }

  if (loading) {
    return <p>Loading...</p>;
  }

  const parameters = [ "courier", "totalWeight", "totalCost", "shipmentPrice", "ivaPrice"];

  return (
    <div className="incomingShipmentsSection tableSection">
      {
                isMobileDevice ? <MobileIncomingStock shipments={shipments}  parameters={parameters}/> :<>
        <div className="sectionTitle">
            <span>Incoming Shipments</span>
        </div>
        <div className="addButton" onClick={() => {window.location.href = "/database/incomingstock/add"}}>
            Add Shipment
        </div>
        <GenericTable 
            columns={["Courier", "State", "Tracking Number", "Total Weight", "Total Cost", "Shipment Price", "IVA Price"]}
            keys={["courier", "state", "trackingNumber", "totalWeight", "totalCost", "shipmentPrice", "ivaPrice"]}
            data={shipments}
            onRowClick={(row) => {
                console.log("test",row);
                setPopUpContent(row);
            }}
        />
        <PopupItem  onClose={() => setPopUpContent(null)}  visible={popUpContent}>
            {
                popUpContent && <IncomingShippingDetails shipment={popUpContent}/>
            }
        </PopupItem>
        </>
      }
    </div>
  );
}

export default IncomingShipments;