import '../../../styles/SelectImage.css';


function SelectImage({ images, imageAdd, imageRemove, selectedImages }) {

    return (
        <div className="select-image">
            <h3>Select images</h3>
            <div className="images">
                {images.map((image, index) => (
                    <div key={index} className="image" onClick={() => imageAdd(image)}>
                        <img src={image.image} alt="product" style={{height: '100px', objectFit: 'contain', borderRadius: '5px'}}/>
                    </div>
                ))}
            </div>
            <div className="selected-images">
                {console.log(selectedImages)}
                {selectedImages.map((image, index) => (
                    <div key={index} className="image" onClick={() => imageRemove(image)}>
                        <img src={image} alt="product" style={{height: '100px', objectFit: 'contain', borderRadius: '5px'}}/>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default SelectImage;