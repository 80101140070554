import {ReactComponent as BackButton} from '../../svgs/backButton.svg';

function InnerPopupItem({ children, onClose, visible }) {
  if (!visible) return null;
  return (
    <div className="popupItem" style={{ width: "100%" , height: "100%"}}>
          <div className="popupItemContainer" style={{ width: "100%" , height: "100%"}}>
            <div className="backButton" onClick={onClose}>
              <BackButton />
            </div>
            {children}
          </div>
        </div>
  );
}

export default InnerPopupItem;