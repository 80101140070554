import React from 'react';
import '../../styles/Mobile/MobileTable.scss';


function DownArrow() {
    return (
        <svg z-index='0' viewBox="0 0 448 512" height="0.9em" width="0.9em" xmlns="http://www.w3.org/2000/svg"><path d="M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z" fill="#fff"></path></svg>
    );
}

const ItemCard = ({ item , parameters }) => {
  return (
    <div className="item-card">
      <div className="item-header">
        <div className="item-name">{item.name?item.name:item.id}</div>
        <div className="right-arrow">
            <DownArrow />
        </div>
      </div>
      <div className="divider"/>

      <div className="item-details">
        {
            //display only if there is an image
            item.images && item.images.length > 0 &&
            <div className="left-side">
                <div className="item-image item-param">
                    <img src={item.images[0].image} alt={item.name} />
                </div>
            </div>
        }
        <div className="right-side">
            {
                parameters && parameters.map((param, index) => (
                    <div className="item-param" key={index}>
                        <span className="param-title">{param}:</span>
                        <span className="param-value">{item[param]}</span>
                    </div>
                ))
            }
            {/* <div className="item-param">
                <span className="param-title">Type:</span>
                <span className="param-value">{item.type}</span>
            </div>
            <div className="item-param">
                <span className="param-title">Subtype:</span>
                <span className="param-value">{item.subType}</span>
            </div>
            <div className="item-param">
                <span className="param-title">Price:</span>
                <span className="param-value">{item.estimated_cost.toFixed(2)+" €"}</span>
            </div>
            <div className="item-param">
                <span className="param-title">Origin:</span>
                <span className="param-value">{item.origin}</span>
            </div> */}
        </div>
      </div>
    </div>
  );
};


function MobileTable({title,items,parameters}) {
    return (
        <div className="item-list">
            <h3 className="item-list-title">{title}</h3>
            <button className="sort-dropdown">
                <div className="dropdown-icon">
                    <div/>
                    <div/>
                    <div/>
                </div>
                <span>Filter</span>
                <div className="arrow-down">
                    <DownArrow />
                </div>
            </button>
            <div className="item-cards">
                {items && items.map((item, index) => (
                    <ItemCard key={index} item={item} parameters={parameters} />
                ))}
            </div>
        </div>
      );
    };

export default MobileTable;