import React, {useState,useEffect} from 'react';
import '../styles/MultiSelect.scss';


function MultiSelectItem({item, onClick}){
    return(
        <div className="itemToSelect" onClick={onClick}>
            <div className="item-image">
                <img src={item.images[0].image} alt={item.name} />
            </div>
            <div className="item-name">
                {item.name}
            </div>
        </div>
    )
}


function MultiSelect({items, onChange}){
    const [selectedItems, setSelectedItems] = useState([]);
    const [itemsToSelect, setItemsToSelect] = useState([]);
    const [selectedPage, setSelectedPage] = useState(0);
    const [toSelectPage, setToSelectPage] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(3);


    useEffect(() => {
        setItemsToSelect(items);
    },[items]);

    useEffect(() => {
        //pass ids of selected items to parent component
        onChange(selectedItems.map((item) => item.id));
    },[selectedItems]);

    return(
        <div className="multiSelect">
            <div className="selectedItems">
                <div className="items">
                    {
                        //display selected items (only itemsPerPage at a time)
                        selectedItems && selectedItems.slice(selectedPage*itemsPerPage, selectedPage*itemsPerPage+itemsPerPage).map((item) => (
                            <MultiSelectItem item={item} onClick={() => {
                                setSelectedItems(selectedItems.filter((selectedItem) => selectedItem !== item));
                                setItemsToSelect([...itemsToSelect, item]);
                            }} />
                        ))
                    }
                </div>
                {/* <div className="clear" onClick={() => {
                    setSelectedItems([]);
                    setItemsToSelect(items);
                }}>
                    Clear
                </div> */}
                <div className='scroll-container'>
                    <button className='scroll-prev' onClick={() => {
                        if(selectedPage > 0){
                            setSelectedPage(selectedPage-1);
                        }
                    }
                    } disabled={selectedPage === 0}>
                        Prev
                    </button>
                    <button className='scroll-next' onClick={() => {
                        if(selectedPage < Math.floor(selectedItems.length/itemsPerPage)){
                            setSelectedPage(selectedPage+1);
                        }
                    }
                    } disabled={selectedPage === Math.floor(selectedItems.length/itemsPerPage)}>
                        Next
                    </button>
                </div>
            </div>
            <div className="itemsToSelect">
                <div className="items">
                {
                    itemsToSelect && itemsToSelect.slice(toSelectPage*itemsPerPage, toSelectPage*itemsPerPage+itemsPerPage).map((item) => (
                        <MultiSelectItem item={item} onClick={() => {
                            setItemsToSelect(itemsToSelect.filter((selectedItem) => selectedItem !== item));
                            setSelectedItems([...selectedItems, item]);
                        }} />
                    ))

                }
                </div>
                <div className='scroll-container'>
                    <button className='scroll-prev' onClick={() => {
                        if(toSelectPage > 0){
                            setToSelectPage(toSelectPage-1);
                        }
                    }
                    } disabled={toSelectPage === 0}>
                        Prev
                    </button>
                    <button className='scroll-next' onClick={() => {
                        if(toSelectPage < Math.floor(itemsToSelect.length/itemsPerPage)){
                            setToSelectPage(toSelectPage+1);
                        }
                    }
                    } disabled={toSelectPage === Math.floor(itemsToSelect.length/itemsPerPage)}>
                        Next
                    </button>
                </div>
            </div>
        </div>
    )
}

export default MultiSelect;