import React from 'react';
import '../../../styles/Mobile/stock.scss';
import MobileTable from '../../../components/Table/MobileTable';

// function DownArrow() {
//     return (
//         <svg z-index='0' viewBox="0 0 448 512" height="0.9em" width="0.9em" xmlns="http://www.w3.org/2000/svg"><path d="M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z" fill="#fff"></path></svg>
//     );
// }

// const ProductCard = ({ product }) => {
//   return (
//     <div className="product-card">
//       <div className="product-header">
//         <div className="product-name">{product.name}</div>
//         <div className="right-arrow">
//             <DownArrow />
//         </div>
//       </div>
//       <div className="divider"/>

//       <div className="product-details">
//         <div className="left-side">
//             <div className="product-image product-param">
//                 <img src={product.images[0].image} alt={product.name} />
//             </div>
//         </div>
//         <div className="right-side">
//             <div className="product-param">
//                 <span className="param-title">Type:</span>
//                 <span className="param-value">{product.type}</span>
//             </div>
//             <div className="product-param">
//                 <span className="param-title">Subtype:</span>
//                 <span className="param-value">{product.subType}</span>
//             </div>
//             <div className="product-param">
//                 <span className="param-title">Price:</span>
//                 <span className="param-value">{product.estimated_cost.toFixed(2)+" €"}</span>
//             </div>
//             <div className="product-param">
//                 <span className="param-title">Origin:</span>
//                 <span className="param-value">{product.origin}</span>
//             </div>
//         </div>
//       </div>
//     </div>
//   );
// };


// function MobileStock({products}) {
//     return (
//         <div className="product-list">
//             <h3 className="product-list-title">Clients</h3>
//             <button className="sort-dropdown">
//                 <div className="dropdown-icon">
//                     <div/>
//                     <div/>
//                     <div/>
//                 </div>
//                 <span>Signal Strength (low first)</span>
//                 <div className="arrow-down">
//                     <DownArrow />
//                 </div>
//             </button>
//             <div className="product-cards">
//                 {products && products.map((product, index) => (
//                     <ProductCard key={index} product={product} />
//                 ))}
//             </div>
//         </div>
//       );
//     };

// export default MobileStock;


function MobileStock({products,parameters}) {
    return(
        <MobileTable title="Products" items={products} parameters={parameters} />
    )
}

export default MobileStock;