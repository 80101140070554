import { Navigate } from 'react-router-dom';
import '../styles/header.scss';
import '../styles/Mobile/header.scss';
import react, {useEffect, useState} from 'react';

import {ReactComponent as Stock} from '../svgs/database/stock.svg';
import {ReactComponent as Purchases} from '../svgs/database/purchases.svg';
import {ReactComponent as IncomingStock} from '../svgs/database/incomingstock.svg';
import {ReactComponent as Shipments} from '../svgs/database/shipments.svg';
import {ReactComponent as Insertions} from '../svgs/database/insertions.svg';
import { ACCESS_TOKEN } from '../constants';

const DatabaseFields = [
    {name: 'Stock', link: '/database/stock',icon: <Stock />},
    {name: 'Purchases', link: '/database/purchases',icon: <Purchases />},
    {name: 'Incoming Stock', link: '/database/incomingstock',icon: <IncomingStock />},
    {name: 'Insertion Batches', link: '/database/insertion_batches',icon: <Insertions />},
    {name: 'Insertions', link: '/database/insertions',icon: <Insertions />},
    {name: 'Shipments', link: '/database/shipments',icon: <Shipments />},
];

const AnalitycsFields = [
    {name: 'Analitycs 1', link: '/analitycs1'},
    {name: 'Analitycs 2', link: '/analitycs2'},
    {name: 'Analitycs 3', link: '/analitycs3'},
    {name: 'Analitycs 4', link: '/analitycs4'},
];

const ToolsFields = [
    {name: 'Import Data', link: '/tools/import_data'},
    {name: 'Tools 2', link: '/tools2'},
    {name: 'Tools 3', link: '/tools3'},
];

function DownArrow() {
    return (
        <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 448 512" className="w-2" aria-hidden="true" tabIndex="-1" height="0.9em" width="0.9em" xmlns="http://www.w3.org/2000/svg"><path d="M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z"></path></svg>
    );
}

function Menu(fields, className) {
    const handleClick = (e,redirect) => {
        e.preventDefault();
        window.location.href = redirect;
    }
    return (
        <ul className={"dropdown hide " + className}>
            {fields.map((field) => (
                <li key={field.name} onClick={(e)=>handleClick(e,field.link)}>
                    {
                        field.icon ? field.icon : ''
                    }
                    <a href={field.link}>{field.name}</a>
                </li>
            ))}
        </ul>
    );
}


function HiddenMenuList({fields}) {
    return (
        <>
        {
            fields.map((field) => (
                <div key={field.name} className="item">
                    {
                        field.icon ? field.icon : ''
                    }
                    <a href={field.link}>{field.name}</a>
                </div>
            ))
        }
        </>
    );
}

function HiddenMenu() {
    return (
        <div className="hidden-menu hidden">
            <div className="content">
                <div className="menu">
                    <div className="menu-item">
                        <span className="title">
                            DATABASE
                        </span>
                        <div className="divisor"></div>
                        <HiddenMenuList fields={DatabaseFields} />
                    </div>
                    <div className="menu-item">
                        <span className="title">
                            ANALYTICS
                        </span>
                        <div className="divisor"></div>
                        <HiddenMenuList fields={AnalitycsFields} />
                    </div>
                    <div className="menu-item">
                        <span className="title">
                            TOOLS
                        </span>
                        <div className="divisor"></div>
                        <HiddenMenuList fields={ToolsFields} />
                    </div>
                </div>
                <div className="auth-content">
                    <a className="login" href="/login">Login</a>
                    <a className="register" href="/register">Register</a>
                </div>
            </div>
        </div>
    );
}

function MobileMenu(){
    const [activeMenu, setActiveMenu] = useState(false);
    const showMenu = (e) => {
        //toggle class active
        setActiveMenu(!activeMenu);
        const menu = document.querySelector('.hidden-menu');
        menu && menu.classList.toggle('hidden');
    }
    return (
        <div className="mobile-menu">
            <div className="logo">
                <img src={`${process.env.PUBLIC_URL}/images/logo.png`} alt='logo'/>
            </div>
            <div className={`menu-icon ${activeMenu?'active':''}`} onClick={(e)=>showMenu(e)}>
                <div></div>
                <div></div>
                <div></div>
            </div>
            <HiddenMenu />
        </div>
    )
}

function Header({isAuth, setIsAuth}) {
    const [activeMenu, setActiveMenu] = useState(null);  
    
    //check if is mobile device
    const [isMobileDevice, setIsMobileDevice] = useState(window.innerWidth < 768);
  
    useEffect(() => {
      window.addEventListener('resize', () => {
        setIsMobileDevice(window.innerWidth < 768);
      });
    }, []);
  

    useEffect(() => {
        //check if the user is logged in
        if(localStorage.getItem(ACCESS_TOKEN)) {
            setIsAuth(true);
        }


        //if clicked outside the dropdown menu close it
        document.addEventListener('click', (e) => {
            if (isMobileDevice) {
                return
            }


            if (!e.target.closest('nav')) {
                document.querySelectorAll('ul.dropdown').forEach((dropdown) => {
                    dropdown && dropdown.classList.add('hide');
                });
                document.querySelector('.overlay') && document.querySelector('.overlay').classList.add('low-prio');
            }
        });
    });




    //prevent defoult action of a tag
    const handleClick = (e,type) => {
        e.preventDefault();

        if (isMobileDevice) {
            return
        }

        //get the dropdown menu ul.dropdown witch classname .type
        const dropdown = document.querySelector(`ul.dropdown.${type}`);

        //toggle the hide class
        dropdown && dropdown.classList.toggle('hide');

        //check in all the dropdowns if there is any that is not hidden
        let count = 0;
        document.querySelectorAll('ul.dropdown').forEach((dropdown) => {
            if(dropdown && !dropdown.classList.contains('hide')) {
                count++;
            }
        });
        
        console.log(count);

        if (count > 0) {
            document.querySelector('.overlay') && document.querySelector('.overlay').classList.remove('low-prio');
        }
        else {
            document.querySelector('.overlay') && document.querySelector('.overlay').classList.add('low-prio');
        }
    }

    const goHome = () => {
        window.location.href = '/';
    }

  return (
    <header className={`header ${isMobileDevice ? 'mobile' : 'desktop'}`}>
        {
            isMobileDevice ?
            <MobileMenu />
            :
            <>
                <div className="content">
                    <div className="logo">
                        <img src={`${process.env.PUBLIC_URL}/images/logo.png`} alt='logo'  onClick={goHome}/>
                    </div>
                    <nav>
                        <ul>
                            <li onClick={(e)=>handleClick(e,'database')}>
                                <a href="/database">Database</a>
                                <DownArrow />
                            </li>
                            <li onClick={(e)=>handleClick(e,'analitycs')}>
                                <a href="/analitycs">Analitycs</a>
                                <DownArrow />
                            </li>
                            <li onClick={(e)=>handleClick(e,'tools')}>
                                <a href="/tools">Tools</a>
                                <DownArrow />
                            </li>
                        </ul>
                    </nav>
                    <div className="authContent">
                        {
                            //check if the user is logged in
                            isAuth ? 
                            <>
                                <a className="logout" href="/logout">Logout</a>
                            </>
                            : 
                            <>
                                <a className="login" href="/login">Login</a>
                                <a className="register" href="/register">Register</a>
                            </>
                        }

                        
                    </div>
                </div>
                <div className="overlay low-prio">
                    <div className="ov-content">
                        {Menu(DatabaseFields, 'database')}
                        {Menu(AnalitycsFields, 'analitycs')}
                        {Menu(ToolsFields, 'tools')}
                    </div>
                </div>
            </>
    }
    </header>
  );
}

export default Header;