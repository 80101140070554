import React, { useEffect,useState } from 'react';
import '../../../styles/purchaseDetails.scss';
import api from '../../../api';
import {ReactComponent as Remove} from '../../../svgs/remove.svg';
import InnerPopupItem from '../../../components/popUp/InnerPopupItem';
import IncomingShipmentDetails from '../IncomingShipments/IncomingShippingDetails';
import ProductCreation from '../Products/ProductCreation';
import {ReactComponent as BackButton} from '../../../svgs/backButton.svg';

function MiniProduct({purchase,product, children, onRemove,setProduct}) {

    const editPercentage = (e) => {
      
      let parent = e.target;

      let percentage = ((product.estimated_cost/purchase.price) * 100).toFixed(2);

      let percentageElement = document.createElement('input');
      percentageElement.value = percentage;
      percentageElement.className = 'product-percentage';
      parent.replaceWith(percentageElement);

      percentageElement.focus();

      percentageElement.addEventListener('blur', (e) => {
        let newPercentage = parseFloat(percentageElement.value);
        updateProduct(newPercentage);
      });
      
      function updateProduct(newPercentage) {
        if (newPercentage >= 0 && newPercentage <= 100) {
          api.patch(`/api/products/update/${product.id}/`, {
            estimated_cost: (purchase.price * newPercentage / 100)
          }).then((res) => {
            product.estimated_cost = (purchase.price * newPercentage / 100);
            percentageElement.replaceWith(parent);
            setProduct(
              {
                ...product,
                estimated_cost: (purchase.price * newPercentage / 100)
              }
            )
          }).catch((err) => alert(err));
        } else {
          percentageElement.replaceWith(parent);
        }
      }

      percentageElement.addEventListener('keydown', (e) => {
        if (e.key === 'Enter') {
          let newPercentage = parseFloat(percentageElement.value);
          if (newPercentage >= 0 && newPercentage <= 100) {
            api.patch(`/api/products/update/${product.id}/`, {
              estimated_cost: (purchase.price * newPercentage / 100)
            }).then((res) => {
              product.estimated_cost = (purchase.price * newPercentage / 100);
              percentageElement.replaceWith(parent);
            }).catch((err) => alert(err));
          } else {
            percentageElement.replaceWith(parent);
          }
        }
        if (e.key === 'Escape') {
          percentageElement.replaceWith(parent);
        }
      }

      );


    }

    return (
        <div className="product">
            {
            onRemove && <Remove className='remove-button' onClick={onRemove}/>
            }
            <div className="product-image">
              <img src={product.images[0].image} alt={product.name} />
            </div>
            <div className="product-info">
              <span className='product-title' title={product.name}>{product.name}</span>
              <hr />
              <span className='product-percentage' onClick={(e)=>editPercentage(e)}>{((product.estimated_cost/purchase.price) * 100).toFixed(2) + "%"}</span>
              <br />
              <span className='product-price'>{product.estimated_cost.toFixed(2) + " €"}</span>
            </div>
            <div className='add-item-button-container'>
              {children}
            </div>
        </div>
        );
    }

function PurchaseDetails({purchase, children, onRemove}) {

    const [productList, setProductList] = React.useState([]);

    const [innerPopupContent, setInnerPopupContent] = React.useState(null);

    const [productCreationContent, setProductCreationContent] = React.useState(null);

    const shipment_link = new URLSearchParams(window.location.search).get('shipment_link');


    const handleAddItemClick = () => {
        if (productList.length === 0) {
            fetchProductsList();
        } else {
            setProductList([]);
        }
    }

    const fetchProductsList = () => {
        api.get("/api/products/").then((res) => res.data).then((data) => {
            data = data.filter((product) => product.boughtStock === null);
            setProductList(data);
        }).catch((err) => alert(err));
    }

    const handleAddingProduct = (product) => {
        api.patch(`/api/products/update/${product.id}/`, {
            boughtStock: purchase.id
        }).then((res) => {
            setProductList(productList.filter((p) => p.id !== product.id));
            purchase.products.push(product);
        }
        ).catch((err) => alert(err));
    }

    const removeProductHandler = (product) => {
        api.patch(`/api/products/update/${product.id}/`, {
            boughtStock: null
        }).then((res) => {
            setProductList([...productList, product]);
            purchase.products = purchase.products.filter((p) => p.id !== product.id);
        }).catch((err) => alert(err));
    }

    const [imagesIndex, setImagesIndex] = React.useState(0);


    const handleShipmentClick = () => {
        api.get(`/api/incoming_shipments/${purchase.incomingShipment}/`).then((res) => res.data).then((data) => {
          console.log(data);
          setInnerPopupContent(data);
        }).catch((err) => alert(err));
    }

    const handleCreateProductClick = () => {
        setProductCreationContent(purchase);
    }

    const goBackToShipment = () => {
        window.location.href = '/database/incomingstock?shipment_id=' + shipment_link;
    }


    const setProduct = (product) => {
      //replace the product in the products list
      setProductList(productList.map((p) => p.id === product.id ? product : p));

    }

    const getDiff = () => {
      let diff = purchase.price - purchase.products.reduce((acc, product) => acc + product.estimated_cost, 0);

      if (diff > 0 + (purchase.price * 0.015)) {
        return <span className='red'>{diff.toFixed(2) + " €"}</span>;
      }
      else if (diff < 0 - (purchase.price * 0.015)) {
        return <span className='red'>{diff.toFixed(2) + " €"}</span>;
      }
      else {
        return <span className='green'>{diff.toFixed(2) + " €"}</span>;
      }
    }

  return (
  <div className="purchase-details">
    {
      shipment_link && <div className="backButton" onClick={goBackToShipment}>
      <BackButton />
    </div>
    }
    <h2>Purchase Details</h2>
    <div className="content">
      <div className="info-section">
        <h3>IMAGES</h3>
          <div className="images">
            {
              purchase.images && purchase.images.length > 0 &&
              <img src={purchase.images[imagesIndex]!== undefined ? purchase.images[imagesIndex]['image'] : ""} alt={purchase.name} className="main-image" />
            }
            <div className="scroll-left" onClick={() => setImagesIndex(imagesIndex === 0 ? purchase.images.length - 1 : imagesIndex - 1)}>&lt;</div>
            <div className="scroll-right" onClick={() => setImagesIndex(imagesIndex === purchase.images.length - 1 ? 0 : imagesIndex + 1)}>&gt;</div>
            <div className="images-indicators">
              {/*Inserisci i pallini pieni e vuoti per indicare lo scorrimento delle imaggini e la current*/}
              {
                purchase.images.map((image, index) => <div key={index} className={imagesIndex === index ? "indicator active" : "indicator"}></div>)
              }
            </div>
          </div>
        <h3>INFO</h3>
            <p><span className='title'>Name</span> <span title={purchase.name} className="value">{purchase.name}</span></p>
            {/* <p><span>Status</span> <span>{purchase.state} <span className="status-indicator"></span></span></p> */}
            <p><span className='title'>Purchase Link</span> <a href={purchase.link} target="_blank" rel="noreferrer" className="value">{purchase.link}</a></p>
            <p><span className='title'>Shipment ID</span>  <span className='shipmentId' onClick={handleShipmentClick}>{purchase.incomingShipment} (Click to open)</span></p>
            <p><span className='title'>Price</span> {purchase.price.toFixed(2) + " €"}</p>
            {/* Price of all products (estimated cost) to see if something missing */}
            <p><span className='title'>Estimated Cost</span> {purchase.products.reduce((acc, product) => acc + product.estimated_cost, 0).toFixed(2) + " €"}</p>
            { /*Calculate if something is missing from Price-extCost red if yes green if good (good is 0 with a margin of 1.5%)*/ }
            {
              getDiff()
            }
            <p><span className='title'>Weight</span> {purchase.weight + " g"}</p>
      </div>
      <div className="products-section">
        <h3>PRODUCTS</h3>
        <div className="products">
          <button className="add-item-button" onClick={handleCreateProductClick}>CREATE PRODUCT</button>
          {
            purchase.products && purchase.products.map((product, index) => <MiniProduct product={product} key={index} onRemove={() => removeProductHandler(product)} purchase={purchase} setProduct={(product) => setProduct(product)} />)
          }
          <button className="add-item-button" onClick={handleAddItemClick}>ADD PRODUCT</button>
          {
            productList && productList.map((product, index) => {
              return(
                <MiniProduct product={product} key={index} purchase={purchase}>
                  <button className="add-item-button" onClick={()=>handleAddingProduct(product)}>ADD</button>
                </MiniProduct>
              )
            }
          )
          }
        </div>
      </div>
    </div>
    <InnerPopupItem  onClose={() => setInnerPopupContent(null)} visible={innerPopupContent}>
      {
        innerPopupContent && <IncomingShipmentDetails shipment={innerPopupContent} />
      }
    </InnerPopupItem>
    <InnerPopupItem  onClose={() => setProductCreationContent(null)} visible={productCreationContent}>
      {
        productCreationContent && <ProductCreation purchase={productCreationContent} close={(product) => {
          setProductCreationContent(null)
          //add product to purchase
          //purchase.products.push(product);
          //concatenate the product to the list of products
          purchase.products = [...purchase.products, ...product];

        }
        }/>
      }
    </InnerPopupItem>
  </div>
  );
}

export default PurchaseDetails;