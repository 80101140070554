import { useEffect, useState } from 'react';
import '../../../styles/addProduct.scss';
import Dropdown from "../../../components/dropdown/Dropdown/Dropdown";
import DropdownItem from "../../../components/dropdown/DropdownItem/DropdownItem";
import api from '../../../api';
import PopupItem from '../../../components/popUp/PopupItem';
import SelectImage from './SelectImage';


function ProductCreation({purchase,close}){
    const [typeInputContent, settypeInputContent] = useState('Select a type');
    const [boughtOrderInputContent, setboughtOrderInputContent] = useState('Select an order');
    const [subTypeInputContent, setSubTypeInputContent] = useState('Select a subtype');
    const [types, setTypes] = useState([]);
    const boughtOrders = [ '#0001', '#0002', '#0003' ];
    const [isLoading, setLoading] = useState(false);
    const [imagesToUpload, setImagesToUpload] = useState([]);
    const [imagesToUpload2, setImagesToUpload2] = useState([]);
    const [popUpSelectImageContent, setPopUpSelectImageContent] = useState(null);
    const [allExtraInfo, setAllExtraInfo] = useState(null);

    useEffect(() => {
        // Fetch types from the API
        api.get("/api/types/")
            .then((res) => res.data)
            .then((data) => {
                setTypes(data);
            })
            .catch((err) => alert(err));


        //fetch extra info from the API
        api.get("/api/extras/")
            .then((res) => res.data)
            .then((data) => {
                console.log("/api/extras/",data);
                setAllExtraInfo(data);
            })
    
    }, []);




    

    const handleClick = (e,type) => {
        settypeInputContent(type);
        setSubTypeInputContent('Select a subtype');
    }

    const handleChangeImage = (e) => {
        setImagesToUpload([...e.target.files]);
    }    

    const addImageToUpload2 = (image) => {
        setImagesToUpload2([...imagesToUpload2, image.image]);
    }

    const removeImageToUpload2 = (image) => {
        console.log(image);
        console.log("imagesToUpload2", imagesToUpload2);
        setImagesToUpload2(imagesToUpload2.filter((img) => img !== image));
    }
    
    const handleSubTypeClick = (e,subtype) => {
        setSubTypeInputContent(subtype);
    }


    const handleSubmit = async (e) => {
        setLoading(true);

        e.preventDefault();
    
        const formData = new FormData();
        formData.append('name', e.target.name.value);
        formData.append('quantity', e.target.quantity.value);
        // Ensure you're appending the type ID
        const selectedType = types.find(type => type.name === typeInputContent);
        if (selectedType) {
            formData.append('type', selectedType.id); // Make sure `selectedType.id` is a valid number
        } else {
            alert("Please select a valid type.");
            setLoading(false);
            return;
        }

        const selectedSubType = types.find(type => type.name === typeInputContent).subtypes.find(subtype => subtype.name === subTypeInputContent);
        if (selectedSubType) {
            formData.append('sub_type', selectedSubType.id); // Make sure `selectedSubType.id` is a valid number
        } else {
            alert("Please select a valid subtype.");
            setLoading(false);
            return;
        }
        
        console.log("imagesToUpload", [...imagesToUpload, ...imagesToUpload2]);
        

        imagesToUpload.forEach((image) => {
            formData.append('images', image);
        });

        imagesToUpload2.forEach((image) => {
            formData.append('already_present_images', image);
        });



        formData.append('origin', purchase.link.split('/')[2]);
        formData.append('boughtStock', purchase.id);
        formData.append('estimated_cost', e.target.approximated_cost.value);
        
        // Add extra info to the form data
        if (allExtraInfo) {
            allExtraInfo.filter(extra => extra.type === types.find(type => type.name === typeInputContent).id).forEach((extra) => {
                console.log(extra);
                console.log(e.target);
                const value = e.target[extra.key].value;
                if (value) {
                    formData.append("product_extras", JSON.stringify({id:extra.id,key: extra.key, value: value}));
                }
            }
            );
        }

        let quantity = e.target.quantity.value;

        formData.append('quantity', quantity);

        // multiple form data for multiple quantities


        let res;
        try {
            res = await api.post('/api/products/', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            console.log(res);

        } catch (error) {
            alert(error);
        } finally {
            //close the popup
            let feedback = []
            for (let i = 0; i < quantity; i++) {
                feedback.push(res.data);
            }
            close(feedback);
        }
    };

    const selectImage = () => {
        //Open the popup to select images from the shipment
        console.log(purchase.images);
        setPopUpSelectImageContent(purchase.images);
    }

    const createSubtype = (e,type) => {
        e.preventDefault();
        const subtype = prompt("Please enter the subtype name");
        if (subtype) {
            api.post('/api/subtypes/', {
                name: subtype,
                type: types.find(t => t.name === type).id
            }).then((res) => {
                types.find(t => t.name === type).subtypes.push(res.data);
                setTypes([...types]);
                setSubTypeInputContent(subtype);
            }).catch((err) => alert(err));
        }
    }

    return (
    <div className="addProductSection miniAddProduct">
        <div className="addProductContainer">
            
            {
                    //show image only if is present in 
                    imagesToUpload.length > 0 ?
                    <>
                        <div className="image-preview">
                            <img src={URL.createObjectURL(imagesToUpload[0])} alt="product"/>
                        </div>
                        <div className="vertical-line">
                        </div>
                    </> : 
                    imagesToUpload2.length > 0 ?
                    <>
                        <div className="image-preview">
                            <img src={imagesToUpload2[0]} alt="product"/>
                        </div>
                        <div className="vertical-line">
                        </div>
                    </> : null
                }
            <div className="addProductForm submit-item-folder">
                
            <div className="addProductHeader">
                <span>Add a new Product</span>
            </div>
                <form onSubmit={handleSubmit}>
                    <input type="text" id="name" name="name" placeholder='Name' />
                    <input type="number" id="quantity" name="quantity" placeholder='Quantity' defaultValue='1'/>
                    <input type='estimated_cost' id='approximated_cost' name='approximated_cost' placeholder='Estimated Cost' />
                    <Dropdown
                        buttonText= {typeInputContent}
                        content={
                            <>
                            {types.map((type, id) => (
                                <DropdownItem key={id} onClick={(e)=>handleClick(e,type.name)}>{`${type.name}`}</DropdownItem>
                            ))}
                            </>
                        }
                    />
                    <div className="divider"></div>
                    {
                        //Subtypes dropdown appear after selecting a type
                        typeInputContent !== 'Select a type' && 
                        <>
                            <Dropdown
                                buttonText= {subTypeInputContent}
                                content={
                                    <>
                                    {types.find(type => type.name === typeInputContent).subtypes.map((subtype, id) => (
                                        <DropdownItem key={id} onClick={(e)=>handleSubTypeClick(e,subtype.name)}>{`${subtype.name}`}</DropdownItem>
                                    ))}
                                    <DropdownItem onClick={(e)=>createSubtype(e,typeInputContent)}>{`Create Subtype`}</DropdownItem>
                                    </>
                                }
                            />
                        </>
                    }
                    {
                        /* Extra Info Appears after selecting a type (all extra infos of the specific type is requested by api) */
                        typeInputContent !== 'Select a type' && allExtraInfo && allExtraInfo.filter(extra => extra.type === types.find(type => type.name === typeInputContent).id).length > 0 &&
                        <>
                            <div className="extra-info">
                                <span>Extra Info</span>
                                <div className="extra-info-container">
                                {
                                    allExtraInfo.filter(extra => extra.type === types.find(type => type.name === typeInputContent).id).map((extra, id) => (
                                        <div key={id} className="extra-info-item">
                                            <span>{extra.key}</span>
                                            {
                                                //switch case for different types of extra info
                                                extra.value_type === 'text' && <input type="text" id={extra.key} name={extra.key} placeholder={extra.key} />
                                            }
                                            {
                                                extra.value_type === 'integer' && <input type="number" id={extra.key} name={extra.key} placeholder={extra.key} />
                                            }
                                            {
                                                extra.value_type === 'float' && <input type="number" id={extra.key} name={extra.key} placeholder={extra.key} />
                                            }
                                            {
                                                extra.value_type === 'boolean' && <input type="checkbox" id={extra.key} name={extra.key} placeholder={extra.key} />
                                            }
                                        </div>
                                    ))
                                }
                                
                                </div>
                            </div>
                            
                            <div className="divider"></div>
                        </>
                        

                    }
                    
                    {/*upload multiple images*/}
                    <div className="images-preview">
                        {
                            imagesToUpload.map((image, index) => (
                                <img key={index} src={URL.createObjectURL(image)} alt="product" style={{height: '100px', objectFit: 'contain', borderRadius: '5px'}} onClick={() => setImagesToUpload(imagesToUpload.filter((img) => img !== image))}/>
                            ))
                        }
                        {
                            imagesToUpload2.map((image, index) => (
                                <img key={index} src={image} alt="product" style={{height: '100px', objectFit: 'contain', borderRadius: '5px'}} onClick={() => removeImageToUpload2(image)}/>
                            ))
                        }
                    </div>
                    <div className="image-picker-options">
                        <input type="file" id="images" name="images" onChange={handleChangeImage} multiple/>
                        <button className='addImageFromShipment' type="button" onClick={selectImage}>Add images from shipment</button>
                    </div>
                    <div className="divider"></div>
                    <button type="submit">Submit</button>
                </form>
            </div>
        </div>
        
        <PopupItem  onClose={() => setPopUpSelectImageContent(null)}  visible={popUpSelectImageContent}>
                    {
                        popUpSelectImageContent && <SelectImage images={popUpSelectImageContent} imageAdd={addImageToUpload2} imageRemove={removeImageToUpload2} selectedImages={imagesToUpload2}/>
                    }
        </PopupItem>
    </div>
    );
}

export default ProductCreation;