import React from 'react';
import MobileTable from '../../../components/Table/MobileTable';


function MobilePurchases({purchases,parameters}) {
    return(
        <MobileTable title="Purchases" items={purchases} parameters={parameters} />
    )
}

export default MobilePurchases;