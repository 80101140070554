import React, { useState,useEffect } from 'react';
import api from '../../../api';
import GenereicAddItemForm from '../../../components/Table/GenericAddItemForm';


function AddInsertionBatch() {

    const [products, setProducts] = useState([]);

    //create handleSubmit, fields to be used in the form
    const handleSubmit = async (form) => {

        await api.post('/api/insertion_batches/', form).then((res) => {
            console.log(res);
            //redirect
            window.location.href = "/database/insertion_batches";
        }).catch((err) => {
            console.log(err);
        });

        console.log(form);
    }

    useEffect(() => {
        api.get("/api/products/").then((res) => res.data).then((data) => {
            console.log("products",data);
            setProducts(data);
        }).catch((err) => alert(err));
    }, [ ]);

    const fields = [
        {
            name: "name",
            label: "Name",
            type: "text"
        },
        {
            name: "images",
            label: "Images",
            type: "text"
        },
        {
            name : "products",
            label : "Choose Products",
            type : "multiselect",
            values : products
        }
    ];

    return (
        <GenereicAddItemForm handleSubmitCall={handleSubmit} fields={fields} />
    );
    
}

export default AddInsertionBatch;