import {ReactComponent as CloseButton} from '../../svgs/close.svg';

function PopupItem({ children, onClose, visible }) {
  if (!visible) return null;
  return (
    <div className="popupItem">
          <div className="popupItemContainer">
            <div className="exitButton" onClick={onClose}>
              <CloseButton />
            </div>
            {children}
          </div>
        </div>
  );
}

export default PopupItem;